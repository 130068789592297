.main-login {
  background-color: #436996;
}

.main-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-login {
  width: 65vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.right-login {
  width: 35vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 10px 40px #00000056;
}

.card-login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 35px;
  background-color: #fff;
  border-radius: 20px;
  /* box-shadow: 0px 10px 40px #00000056; */
}

.card-login h1 {
  color: #436996;
  font-weight: 800;
  margin: 0;
  margin-bottom: 40px;
}

.card-login h2 {
  color: #436996;
  font-weight: 800;
  margin: 0;
}

.textfield {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.textfield input {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 15px;
  background: #e8f0fe;
  font-size: 12pt;
  outline: none;
  box-sizing: border-box;
}

.textfield label {
  margin-top: 15px;
  color: #436996;
  margin-bottom: 10px;
}

.textfield input::placeholder {
  color: black
}

.btn-login {
  width: 100%;
  padding: 16px 0px;
  margin: 25px;
  border: none;
  border-radius: 8px;
  outline: none;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 3px;
  color: white;
  background-color: #436996;
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #00000056;
  /* transition: 0.1s; */
}


@media only screen and (min-width: 1600px) {
  .card-login {
    width: 80%;
  }

  .left-login-image {
    width: 20vw;
  }
}

@media only screen and (max-width: 600px) {

  .main-login {
    flex-direction: column;
  }

  .left-login {
    width: 100%;
    height: auto;
    display: none;
  }

  .right-login {
    width: 100%;
    height: auto;
  }

  .left-login-image {
    width: 50vw;
  }

  .card-login {
    width: 100%;
  }

}